import { NavLink } from "react-router-dom";
import { DataLinks } from "./data-links";
import Logo from "../../assets/logo.png";
import Cookies from "universal-cookie/es6";
import "./saidbar.css";
import { useEffect } from "react";

function Saidbar() {
  const cookies = new Cookies();
  const HandelOut = async (e) => {
    await cookies.remove("autToken");
    window.location = "/";
  };
  return (
    // <div className="navigation">
    //   <div className={styled.Wrapper}>
    //     <ul>
    //       {DataLinks.map((elem, index) => (
    //         <li>
    //           <i class={elem.icon}></i>
    //           <NavLink to={elem.path}>{elem.title}</NavLink>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>
    <div class="navigation">
      <ul>
        <li>
          <a href="/dashboard">
            <img className="logo" src={Logo} alt="photo" />
          </a>
        </li>
        {DataLinks.map((elem, index) => (
          <li key={index}>
            <NavLink to={elem.path}>
              <span class="icon">
                <ion-icon name={elem.icon}></ion-icon>
              </span>
              <span class="title">{elem.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <button onClick={HandelOut} className="btn">
        LogOut
      </button>
    </div>
  );
}

export default Saidbar;
