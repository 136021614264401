import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGalery, PutGalery, PutUpload } from "../../../redux/store/galery";

function GaleryPut({ modal, handleClose, id }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const onBtnClick = (e) => {
    e.preventDefault();
    inputFileRef.current.click();
  };
  const onFilechange = (e) => {
    dispatch(PutUpload(e));
  };
  const Loading = useSelector((state) => state.galery.uploadPutGalery.Loading);
  const DataImg = useSelector((state) => state.galery.uploadPutGalery.data);
  const title = useRef();
  const address = useRef();
  const year = useRef();
  const month = useRef();
  const Put_id = id;
  const HandePut = async (e) => {
    e.preventDefault();
    const body = {
      title: title.current.value,
      adress: address.current.value,
      year: year.current.value,
      energy_month: month.current.value,
      image: DataImg,
    };
    await dispatch(PutGalery({ body, id: Put_id }));
    dispatch(GetGalery());
    handleClose();
  };
  const Data = useSelector((state) => state.galery.getGalery.Data);
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {Data.map((elem, index) =>
              elem.id == id ? (
                <>
                  <div className={styled.Wrapper} key={index}>
                    <form className={styled.form} onSubmit={HandePut}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputFileRef}
                        onChange={onFilechange}
                      />
                      <button onClick={onBtnClick}>
                        {Loading ? <>Loading...</> : <>Edit img </>}
                      </button>
                      <input ref={title} type="text" placeholder={elem.title} />
                      <input
                        ref={address}
                        type="text"
                        placeholder={elem.adress}
                      />
                      <input ref={year} type="number" placeholder={elem.year} />
                      <input
                        ref={month}
                        type="text"
                        placeholder={elem.energy_month}
                      />
                      <button type="submit" className={styled.postBtn}>
                        Edit Events
                      </button>
                    </form>
                  </div>
                </>
              ) : null
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default GaleryPut;
