import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services";

export const PostCategory = createAsyncThunk("Category/post", async (body) => {
  return axios.post(`${Api_Url}/category`, body).then((res) => res);
});
export const GetCategory = createAsyncThunk("Category/get", async () => {
  return await axios
    .get(`${Api_Url}/category`)
    .then((response) => response.data);
});
export const DeleteCategory = createAsyncThunk(
  "Category/delete",
  async (id) => {
    return await axios
      .delete(`${Api_Url}/category/${id}`)
      .then((response) => response.data);
  }
);
export const PutCategory = createAsyncThunk(
  "Category/put",
  async ({ body, id }) => {
    return await axios
      .put(`${Api_Url}/category/${id}`, body)
      .then((response) => console.log(response));
  }
);
const CategorySlice = createSlice({
  name: "Category",
  initialState: {
    postCategory: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getCategory: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteCategory: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putCategory: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    [PostCategory.pending]: (state, action) => {
      state.postCategory.Loading = true;
    },
    [PostCategory.fulfilled]: (state, action) => {
      state.postCategory.Error = false;
      state.postCategory.Success = true;
      state.postCategory.Loading = false;
    },
    [PostCategory.rejected]: (state, action) => {
      state.postCategory.Error = true;
      state.postCategory.Success = false;
      state.postCategory.Loading = false;
    },
    [GetCategory.pending]: (state, action) => {
      state.getCategory.Loading = true;
    },
    [GetCategory.fulfilled]: (state, action) => {
      state.getCategory.Error = false;
      state.getCategory.Success = true;
      state.getCategory.Loading = false;
      state.getCategory.Data = action.payload;
    },
    [GetCategory.rejected]: (state, action) => {
      state.getCategory.Error = true;
      state.getCategory.Success = false;
      state.getCategory.Loading = false;
      state.getCategory.Data = [];
    },
    [DeleteCategory.pending]: (state, action) => {
      state.deleteCategory.Loading = true;
    },
    [DeleteCategory.fulfilled]: (state, action) => {
      state.deleteCategory.Error = false;
      state.deleteCategory.Success = true;
      state.deleteCategory.Loading = false;
    },
    [DeleteCategory.rejected]: (state, action) => {
      state.deleteCategory.Error = true;
      state.deleteCategory.Success = false;
      state.deleteCategory.Loading = false;
    },
    [PutCategory.pending]: (state, action) => {
      state.putCategory.Loading = true;
    },
    [PutCategory.fulfilled]: (state, action) => {
      state.putCategory.Error = false;
      state.putCategory.Success = true;
      state.putCategory.Loading = false;
    },
    [PutCategory.rejected]: (state, action) => {
      state.putCategory.Error = true;
      state.putCategory.Success = false;
      state.putCategory.Loading = false;
    },
  },
});

export default CategorySlice.reducer;
