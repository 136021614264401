import styled, { createGlobalStyle } from "styled-components";

export const Main = styled.div`
  display: flex;
`;
export const GlobalStyle = createGlobalStyle`
  html{
    scroll-behavior:smooth
    }
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden; 
  }
  ul{
  list-style: none;
  padding: 0;
  }
`;

export const WrapperContainer = styled.div`
  width: 80.5%;
  margin-left: auto !important;
  padding: 0px 0px;
`;
