import React from "react";
import AdminCompp from "../../components/admin";

function Admin() {
  return (
    <>
      <AdminCompp />
    </>
  );
}

export default Admin;
