import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCategory, PutCategory } from "../../../redux/store/category";

function CategoryPut({ modal, handleClose, id }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const title = useRef();
  const titleUz = useRef();
  const titleEn = useRef();
  const Put_id = id;
  const HandePut = async (e) => {
    e.preventDefault();
    const body = {
      category_name: title.current.value,
      category_name_uz: titleUz.current.value,
      category_name_en: titleEn.current.value,
    };
    await dispatch(PutCategory({ body, id: Put_id }));
    dispatch(GetCategory());
    handleClose();
  };
  const Data = useSelector((state) => state.category.getCategory.Data);
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {Data.map((elem, index) =>
              elem.id == id ? (
                <>
                  <div className={styled.Wrapper} key={index}>
                    <form className={styled.form} onSubmit={HandePut}>
                      <input
                        ref={title}
                        type="text"
                        placeholder={elem.category_name}
                      />
                      <input
                        ref={titleUz}
                        type="text"
                        placeholder={elem.category_name_uz}
                      />
                      <input
                        ref={titleEn}
                        type="text"
                        placeholder={elem.category_name_en}
                      />
                      <button type="submit" className={styled.postBtn}>
                        Edit Events
                      </button>
                    </form>
                  </div>
                </>
              ) : null
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default CategoryPut;
