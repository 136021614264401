import { configureStore } from "@reduxjs/toolkit";
import Galery from "./store/galery";
import Category from "./store/category";
import Products from "./store/products";
import Images from "./store/images";
import Form from "./store/form";
import Login from "./store/login";
import Admin from "./store/admin";

export const store = configureStore({
  reducer: {
    galery: Galery,
    category: Category,
    products: Products,
    images: Images,
    form: Form,
    login: Login,
    admin: Admin,
  },
});
