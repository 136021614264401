import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAdmin, PostAdmin } from "../../../redux/store/admin";

function AdminPost({ modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const name = useRef();
  const email = useRef();
  const password = useRef();

  const HandePost = async (e) => {
    e.preventDefault();
    const body = {
      name: name.current.value,
      email: email.current.value,
      password: password.current.value,
    };
    await dispatch(PostAdmin(body));
    dispatch(GetAdmin());
    handleClose();
  };

  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <form className={styled.form} onSubmit={HandePost}>
                <input ref={name} type="text" placeholder="name" />
                <input ref={email} type="email" placeholder="email" />
                <input ref={password} type="password" placeholder="password" />
                <button type="submit" className={styled.postBtn}>
                  Add Admin
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AdminPost;
