import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetGalery,
  PostGalery,
  UploadImage,
} from "../../../redux/store/galery";

function GaleryPost({ modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const onBtnClick = (e) => {
    e.preventDefault();
    inputFileRef.current.click();
  };
  const onFilechange = (e) => {
    dispatch(UploadImage(e));
  };
  const Loading = useSelector((state) => state.galery.uploadGalery.Loading);
  const DataImg = useSelector((state) => state.galery.uploadGalery.data);
  const title = useRef();
  const address = useRef();
  const year = useRef();
  const month = useRef();
  const HandePost = async (e) => {
    e.preventDefault();
    const body = {
      title: title.current.value,
      adress: address.current.value,
      year: year.current.value,
      energy_month: month.current.value,
      image: DataImg,
    };
    await dispatch(PostGalery(body));
    dispatch(GetGalery());
    handleClose();
  };

  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <form className={styled.form} onSubmit={HandePost}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  onChange={onFilechange}
                />
                <button onClick={onBtnClick}>
                  {Loading ? <>Loading...</> : <>Add img </>}
                </button>
                <input ref={title} type="text" placeholder="title" />
                <input ref={address} type="text" placeholder="address" />
                <input ref={year} type="text" placeholder="year" />
                <input ref={month} type="text" placeholder="energy_month" />
                <button type="submit" className={styled.postBtn}>
                  Add Galery
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default GaleryPost;
