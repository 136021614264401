import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services";

export const PostImages = createAsyncThunk("Images/post", async (body) => {
  return axios.post(`${Api_Url}/images`, body).then((res) => res);
});
export const GetImages = createAsyncThunk("Images/get", async () => {
  return await axios.get(`${Api_Url}/images`).then((response) => response.data);
});
export const DeleteImages = createAsyncThunk("Images/delete", async (id) => {
  return await axios
    .delete(`${Api_Url}/images/${id}`)
    .then((response) => response.data);
});
export const PutImages = createAsyncThunk(
  "Images/put",
  async ({ body, id }) => {
    return await axios
      .put(`${Api_Url}/images/${id}`, body)
      .then((response) => console.log(response));
  }
);
export const UploadImage = createAsyncThunk("Images/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const PutUpload = createAsyncThunk("Images/uploadPut", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const ImagesSlice = createSlice({
  name: "Images",
  initialState: {
    postImages: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getImages: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteImages: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putImages: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadImages: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutImages: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostImages.pending]: (state, action) => {
      state.postImages.Loading = true;
    },
    [PostImages.fulfilled]: (state, action) => {
      state.postImages.Error = false;
      state.postImages.Success = true;
      state.postImages.Loading = false;
    },
    [PostImages.rejected]: (state, action) => {
      state.postImages.Error = true;
      state.postImages.Success = false;
      state.postImages.Loading = false;
    },
    [GetImages.pending]: (state, action) => {
      state.getImages.Loading = true;
    },
    [GetImages.fulfilled]: (state, action) => {
      state.getImages.Error = false;
      state.getImages.Success = true;
      state.getImages.Loading = false;
      state.getImages.Data = action.payload;
    },
    [GetImages.rejected]: (state, action) => {
      state.getImages.Error = true;
      state.getImages.Success = false;
      state.getImages.Loading = false;
      state.getImages.Data = [];
    },
    [DeleteImages.pending]: (state, action) => {
      state.deleteImages.Loading = true;
    },
    [DeleteImages.fulfilled]: (state, action) => {
      state.deleteImages.Error = false;
      state.deleteImages.Success = true;
      state.deleteImages.Loading = false;
    },
    [DeleteImages.rejected]: (state, action) => {
      state.deleteImages.Error = true;
      state.deleteImages.Success = false;
      state.deleteImages.Loading = false;
    },
    [PutImages.pending]: (state, action) => {
      state.putImages.Loading = true;
    },
    [PutImages.fulfilled]: (state, action) => {
      state.putImages.Error = false;
      state.putImages.Success = true;
      state.putImages.Loading = false;
    },
    [PutImages.rejected]: (state, action) => {
      state.putImages.Error = true;
      state.putImages.Success = false;
      state.putImages.Loading = false;
    },
    [UploadImage.pending]: (state, action) => {
      state.uploadImages.Loading = true;
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.uploadImages.Error = false;
      state.uploadImages.Success = true;
      state.uploadImages.Loading = false;
      state.uploadImages.data = action.payload;
    },
    [UploadImage.rejected]: (state, action) => {
      state.uploadImages.Error = true;
      state.uploadImages.Success = false;
      state.uploadImages.Loading = false;
    },
    [PutUpload.pending]: (state, action) => {
      state.uploadPutImages.Loading = true;
    },
    [PutUpload.fulfilled]: (state, action) => {
      state.uploadPutImages.Error = false;
      state.uploadPutImages.Success = true;
      state.uploadPutImages.Loading = false;
      state.uploadPutImages.data = action.payload;
    },
    [PutUpload.rejected]: (state, action) => {
      state.uploadPutImages.Error = true;
      state.uploadPutImages.Success = false;
      state.uploadPutImages.Loading = false;
    },
  },
});

export default ImagesSlice.reducer;
