import React from "react";
import ProductsComp from "../../components/Products";

function Products() {
  return (
    <>
      <ProductsComp />
    </>
  );
}

export default Products;
