import styled from "./style.module.css";

function HeaderComp({ title, handelOpen }) {
  return (
    <>
      <div className={styled.Wrapper}>
        <div className={styled.header}>
          <h2>{title}</h2>
          <button onClick={handelOpen}>Add+</button>
        </div>
      </div>
    </>
  );
}

export default HeaderComp;
