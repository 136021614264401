import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services";

export const GetForm = createAsyncThunk("Form/get", async () => {
  return await axios.get(`${Api_Url}/form`).then((response) => response.data);
});
export const DeleteForm = createAsyncThunk("Form/delete", async (id) => {
  return await axios
    .delete(`${Api_Url}/form/${id}`)
    .then((response) => response.data);
});
const FormSlice = createSlice({
  name: "Form",
  initialState: {
    getForm: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteForm: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    [GetForm.pending]: (state, action) => {
      state.getForm.Loading = true;
    },
    [GetForm.fulfilled]: (state, action) => {
      state.getForm.Error = false;
      state.getForm.Success = true;
      state.getForm.Loading = false;
      state.getForm.Data = action.payload;
    },
    [GetForm.rejected]: (state, action) => {
      state.getForm.Error = true;
      state.getForm.Success = false;
      state.getForm.Loading = false;
      state.getForm.Data = [];
    },
    [DeleteForm.pending]: (state, action) => {
      state.deleteForm.Loading = true;
    },
    [DeleteForm.fulfilled]: (state, action) => {
      state.deleteForm.Error = false;
      state.deleteForm.Success = true;
      state.deleteForm.Loading = false;
    },
    [DeleteForm.rejected]: (state, action) => {
      state.deleteForm.Error = true;
      state.deleteForm.Success = false;
      state.deleteForm.Loading = false;
    },
  },
});

export default FormSlice.reducer;
