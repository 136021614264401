import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGalery, PutGalery, PutUpload } from "../../../redux/store/galery";
import { GetCategory } from "../../../redux/store/category";
import { GetProducts, PutProducts } from "../../../redux/store/products";

function ProductsPut({ modal, handleClose, id }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const title = useRef();
  const description_uz = useRef();
  const description_en = useRef();
  const description_ru = useRef();
  const [categId, setCategId] = useState();
  const HandelChange = (e) => {
    setCategId(e.target.value);
  };
  const Put_id = id;
  const HandePut = async (e) => {
    e.preventDefault();
    const body = {
      title: title.current.value,
      description_uz: description_uz.current.value,
      description_en: description_en.current.value,
      description_ru: description_ru.current.value,
      category: categId,
    };
    await dispatch(PutProducts({ body, id: Put_id }));
    dispatch(GetProducts());
    handleClose();
  };
  useEffect(() => {
    dispatch(GetCategory());
  }, []);
  const CategoryData = useSelector((state) => state.category.getCategory.Data);
  const Data = useSelector((state) => state.products.getProducts.Data);
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {Data.map((elem, index) =>
              elem.id == id ? (
                <>
                  <div className={styled.Wrapper} key={index}>
                    <form className={styled.form} onSubmit={HandePut}>
                      <input ref={title} type="text" placeholder={elem.title} />
                      <input
                        ref={description_uz}
                        type="text"
                        placeholder={elem.description_uz}
                      />
                      <input
                        ref={description_en}
                        type="number"
                        placeholder={elem.description_en}
                      />
                      <input
                        ref={description_ru}
                        type="text"
                        placeholder={elem.description_ru}
                      />
                      <select onChange={HandelChange}>
                        {CategoryData.map((elem, index) => (
                          <option value={elem.id}>{elem.category_name}</option>
                        ))}
                      </select>
                      <button type="submit" className={styled.postBtn}>
                        Edit Events
                      </button>
                    </form>
                  </div>
                </>
              ) : null
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ProductsPut;
