import React, { useEffect, useState } from "react";
import HeaderComp from "../common/headerCommon";
import { useDispatch, useSelector } from "react-redux";
import { GetGalery } from "../../redux/store/galery";
import { Col, Row } from "react-grid-system";
import styled from "./styled.module.css";
import GaleryPost from "./Post";
import GaleryDelete from "./Delete";
import GaleryPut from "./Put";

function GaleryComponent() {
  const disptach = useDispatch();
  useEffect(() => {
    disptach(GetGalery());
  }, []);
  const Data = useSelector((state) => state.galery.getGalery.Data);
  const [modal, setModal] = useState(false);
  const [delet, setDelete] = useState(false);
  const [put, setPut] = useState(false);
  const [del_id, setDel_id] = useState();
  const [put_id, setPut_id] = useState();

  const HandelOpen = () => {
    setModal(true);
  };
  const HandelClose = () => {
    setModal(false);
  };
  const HandelOpenDel = () => {
    setDelete(true);
  };
  const HandelCloseDel = () => {
    setDelete(false);
  };
  const HandelOpenPut = () => {
    setPut(true);
  };
  const HandelClosePut = () => {
    setPut(false);
  };
  return (
    <>
      <HeaderComp title="Galery" handelOpen={HandelOpen} />
      <Row style={{ margin: 0, padding: 0, width: "97%" }}>
        {Data.map((elem, index) => (
          <Col key={index} xs={4} sm={4} className={styled.Col}>
            <img src={elem.image} alt="photo" />
            <div className={styled.texts}>
              <h2>{elem.title}</h2>
              <h3>{elem.adress}</h3>
              <h3>{elem.energy_month}</h3>
            </div>
            <div className={styled.buttons}>
              <button
                onClick={() => {
                  setDel_id(elem.id);
                  HandelOpenDel();
                }}
                className={styled.delete}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setPut_id(elem.id);
                  HandelOpenPut();
                }}
                className={styled.put}
              >
                Edit
              </button>
            </div>
          </Col>
        ))}
      </Row>
      <GaleryPost modal={modal} handleClose={HandelClose} />
      <GaleryDelete id={del_id} handleClose={HandelCloseDel} modal={delet} />
      <GaleryPut  id={put_id} handleClose={HandelClosePut} modal={put}  />
    </>
  );
}

export default GaleryComponent;
