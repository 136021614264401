import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const SingIn = createAsyncThunk(
  "user/SingInusers",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      let response = await axios.post(
        "https://x8ki-letl-twmt.n7.xano.io/api:BAHjxkQw/auth/login",
        userData
      );
      console.log(response);
      if (response.data.authToken) {
        cookies.set("autToken", response.data.token);
        window.location = "/admin/dashboard";
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return { ...userData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  email: null,
  password: null,
  error: "",
};

const LoginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(SingIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SingIn.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = "";
    });
    builder.addCase(SingIn.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
  },
});

export default LoginSlice.reducer;
