import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetImages,
  PostImages,
  UploadImage,
} from "../../../redux/store/images";
import { GetProducts } from "../../../redux/store/products";

function ImagesPost({ modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const [proId, setProId] = useState();
  const inputFileRef = useRef(null);
  const onBtnClick = (e) => {
    e.preventDefault();
    inputFileRef.current.click();
  };
  const onFilechange = (e) => {
    dispatch(UploadImage(e));
  };
  const HandelChange = (e) => {
    setProId(e.target.value);
  };
  const Loading = useSelector((state) => state.images.uploadImages.Loading);
  const DataImg = useSelector((state) => state.images.uploadImages.data);
  const HandePost = async (e) => {
    e.preventDefault();
    const body = {
      image: DataImg,
      products: proId,
    };
    await dispatch(PostImages(body));
    dispatch(GetImages());
    handleClose();
  };
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  const DataProducts = useSelector((state) => state.products.getProducts.Data);

  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <form className={styled.form} onSubmit={HandePost}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  onChange={onFilechange}
                />
                <select onChange={HandelChange}>
                  {DataProducts.map((elem, index) => (
                    <>
                      <option>Choose Product</option>
                      <option key={index} value={elem.id}>
                        {elem.title}
                      </option>
                    </>
                  ))}
                </select>
                <button onClick={onBtnClick}>
                  {Loading ? <>Loading...</> : <>Add img </>}
                </button>
                <button type="submit" className={styled.postBtn}>
                  Add Image
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ImagesPost;
