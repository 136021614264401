import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services";

export const PostGalery = createAsyncThunk("Galery/post", async (body) => {
  return axios.post(`${Api_Url}/galery`, body).then((res) => res);
});
export const GetGalery = createAsyncThunk("Galery/get", async () => {
  return await axios.get(`${Api_Url}/galery`).then((response) => response.data);
});
export const DeleteGalery = createAsyncThunk("Galery/delete", async (id) => {
  return await axios
    .delete(`${Api_Url}/galery/${id}`)
    .then((response) => response.data);
});
export const PutGalery = createAsyncThunk(
  "Galery/put",
  async ({ body, id }) => {
    return await axios
      .put(`${Api_Url}/galery/${id}`, body)
      .then((response) => console.log(response));
  }
);
export const UploadImage = createAsyncThunk("Galery/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const PutUpload = createAsyncThunk("Galery/uploadPut", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const GalerySlice = createSlice({
  name: "Galery",
  initialState: {
    postGalery: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getGalery: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteGalery: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putGalery: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadGalery: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutGalery: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostGalery.pending]: (state, action) => {
      state.postGalery.Loading = true;
    },
    [PostGalery.fulfilled]: (state, action) => {
      state.postGalery.Error = false;
      state.postGalery.Success = true;
      state.postGalery.Loading = false;
    },
    [PostGalery.rejected]: (state, action) => {
      state.postGalery.Error = true;
      state.postGalery.Success = false;
      state.postGalery.Loading = false;
    },
    [GetGalery.pending]: (state, action) => {
      state.getGalery.Loading = true;
    },
    [GetGalery.fulfilled]: (state, action) => {
      state.getGalery.Error = false;
      state.getGalery.Success = true;
      state.getGalery.Loading = false;
      state.getGalery.Data = action.payload;
    },
    [GetGalery.rejected]: (state, action) => {
      state.getGalery.Error = true;
      state.getGalery.Success = false;
      state.getGalery.Loading = false;
      state.getGalery.Data = [];
    },
    [DeleteGalery.pending]: (state, action) => {
      state.deleteGalery.Loading = true;
    },
    [DeleteGalery.fulfilled]: (state, action) => {
      state.deleteGalery.Error = false;
      state.deleteGalery.Success = true;
      state.deleteGalery.Loading = false;
    },
    [DeleteGalery.rejected]: (state, action) => {
      state.deleteGalery.Error = true;
      state.deleteGalery.Success = false;
      state.deleteGalery.Loading = false;
    },
    [PutGalery.pending]: (state, action) => {
      state.putGalery.Loading = true;
    },
    [PutGalery.fulfilled]: (state, action) => {
      state.putGalery.Error = false;
      state.putGalery.Success = true;
      state.putGalery.Loading = false;
    },
    [PutGalery.rejected]: (state, action) => {
      state.putGalery.Error = true;
      state.putGalery.Success = false;
      state.putGalery.Loading = false;
    },
    [UploadImage.pending]: (state, action) => {
      state.uploadGalery.Loading = true;
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.uploadGalery.Error = false;
      state.uploadGalery.Success = true;
      state.uploadGalery.Loading = false;
      state.uploadGalery.data = action.payload;
    },
    [UploadImage.rejected]: (state, action) => {
      state.uploadGalery.Error = true;
      state.uploadGalery.Success = false;
      state.uploadGalery.Loading = false;
    },
    [PutUpload.pending]: (state, action) => {
      state.uploadPutGalery.Loading = true;
    },
    [PutUpload.fulfilled]: (state, action) => {
      state.uploadPutGalery.Error = false;
      state.uploadPutGalery.Success = true;
      state.uploadPutGalery.Loading = false;
      state.uploadPutGalery.data = action.payload;
    },
    [PutUpload.rejected]: (state, action) => {
      state.uploadPutGalery.Error = true;
      state.uploadPutGalery.Success = false;
      state.uploadPutGalery.Loading = false;
    },
  },
});

export default GalerySlice.reducer;
