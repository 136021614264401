import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImages, PutImages, PutUpload } from "../../../redux/store/images";

function ImagesPut({ modal, handleClose, id }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const onBtnClick = (e) => {
    e.preventDefault();
    inputFileRef.current.click();
  };
  const onFilechange = (e) => {
    dispatch(PutUpload(e));
  };
  const Loading = useSelector((state) => state.images.uploadPutImages.Loading);
  const DataImg = useSelector((state) => state.images.uploadPutImages.data);
  const Put_id = id;
  const [proId, setProId] = useState();
  const HandePut = async (e) => {
    e.preventDefault();
    const body = {
      image: DataImg,
      products: proId,
    };
    await dispatch(PutImages({ body, id: Put_id }));
    dispatch(GetImages());
    handleClose();
  };
  const HandelChange = (e) => {
    setProId(e.target.value);
  };
  const Data = useSelector((state) => state.images.getImages.Data);
  const DataProducts = useSelector((state) => state.products.getProducts.Data);
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {Data.map((elem, index) =>
              elem.id == id ? (
                <>
                  <div className={styled.Wrapper} key={index}>
                    <form className={styled.form} onSubmit={HandePut}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputFileRef}
                        onChange={onFilechange}
                      />
                      <select onChange={HandelChange}>
                        {DataProducts.map((elem, index) => (
                          <>
                            <option>Choose Product</option>
                            <option value={elem.id}>{elem.title}</option>
                          </>
                        ))}
                      </select>
                      <button onClick={onBtnClick}>
                        {Loading ? <>Loading...</> : <>Add img </>}
                      </button>
                      <button type="submit" className={styled.postBtn}>
                        Edit Image
                      </button>
                    </form>
                  </div>
                </>
              ) : null
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ImagesPut;
