import GaleryComponent from "../../components/Galery";

function Galery() {
  return (
    <>
      <GaleryComponent />
    </>
  );
}

export default Galery;
