import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetForm } from "../../redux/store/form";
import styled from "./style.module.css";
import FormDelete from "./Delete";

function FormComp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetForm());
  }, []);
  const Data = useSelector((state) => state.form.getForm.Data);
  const [delet, setDelete] = useState(false);
  const [del_id, setDel_id] = useState();
  const HandelOpenDel = () => {
    setDelete(true);
  };
  const HandelCloseDel = () => {
    setDelete(false);
  };

  return (
    <>
      <div className={styled.Wrappe}>
        <div className={styled.header}>
          <h2>Form</h2>
        </div>
      </div>
      <div className={styled.Wrapper}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Data.map((elem, index) => (
              <tr>
                <td>{elem.name}</td>
                <td>{elem.phone_number}</td>
                <td>
                  <div className={styled.dec}>
                    <p>{elem.description}</p>
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setDel_id(elem.id);
                      HandelOpenDel();
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <FormDelete modal={delet} id={del_id} handleClose={HandelCloseDel} />
    </>
  );
}

export default FormComp;
