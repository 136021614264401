import { Box, Modal } from "@mui/material";
import styled from "./style.module.css";
import { useDispatch } from "react-redux";
import { DeleteAdmins, GetAdmin } from "../../../redux/store/admin";

function DeleteAdmin({ ids, modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const id = ids;
  const HandelDelete = async (e) => {
    e.preventDefault();
    await dispatch(DeleteAdmins(id));
    dispatch(GetAdmin());
    handleClose();
  };
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <h1>Вы точно хотите удалть</h1>
              <div className={styled.buttons}>
                <button onClick={HandelDelete}>Да</button>
                <button onClick={handleClose}>Нет</button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default DeleteAdmin;
