import React, { useEffect, useState } from "react";
import Header from "../common/headerCommon";
import { useDispatch, useSelector } from "react-redux";
import { GetAdmin } from "../../redux/store/admin";
import styled from "./style.module.css";
import DeleteAdmin from "./delete";
import AdminPost from "./post";

function AdminCompp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAdmin());
  }, []);
  const Data = useSelector((state) => state.admin.getAdmin.Data);

  const [delet, setDelete] = useState(false);
  const HandelOpenDel = () => {
    setDelete(true);
  };
  const HandelCloseDel = () => {
    setDelete(false);
  };
  const [delId, setDel] = useState();
  const [modal, setModal] = useState(false);
  const HandelOpen = () => {
    setModal(true);
  };
  const HandelClose = () => {
    setModal(false);
  };

  return (
    <>
      <Header title={"Admin"} handelOpen={HandelOpen} />
      <div className={styled.Wrapper}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            {Data.map((elem, index) => (
              <tr>
                <td>{elem.name}</td>
                <td>{elem.email}</td>
                <td>
                  <button
                    onClick={() => {
                      setDel(elem.id);
                      HandelOpenDel();
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DeleteAdmin modal={delet} handleClose={HandelCloseDel} ids={delId} />
      <AdminPost modal={modal} handleClose={HandelClose} />
    </>
  );
}

export default AdminCompp;
