import Admin from "../pages/Admin";
import Category from "../pages/Category";
import Form from "../pages/Form";
import Galery from "../pages/Galery";
import Home from "../pages/Home";
import ImagesProducts from "../pages/ImageProducts";
import Login from "../pages/Login";
import Products from "../pages/Products";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const Token = cookies.get("autToken");
export const DataRouters = [
  {
    id: 2,
    path: "/admin/galery",
    Element: cookies.get("autToken") ? <Galery /> : null,
  },
  {
    id: 3,
    path: "/admin/category",
    Element: cookies.get("autToken") ? <Category /> : null,
  },
  {
    id: 4,
    path: "/admin/products",
    Element: cookies.get("autToken") ? <Products /> : null,
  },
  {
    id: 5,
    path: "/admin/productsimages",
    Element: cookies.get("autToken") ? <ImagesProducts /> : null,
  },
  {
    id: 6,
    path: "/admin/request",
    Element: cookies.get("autToken") ? <Form /> : null,
  },
  {
    id: 7,
    path: "/",
    Element: cookies.get("autToken") ? null : <Login />,
  },
  {
    id: 8,
    path: "/admin/admin",
    Element: cookies.get("autToken") ? <Admin /> : null,
  },
];
