import AllRouter from "./routers";

function App() {
  return (
    <>
      <AllRouter />
    </>
  );
}

export default App;
