import React from "react";
import CategoriesComp from "../../components/Categories";

function Category() {
  return (
    <>
      <CategoriesComp />
    </>
  );
}

export default Category;
