import React from "react";
import ImagesProductsComp from "../../components/ImagesProducts";

function ImagesProducts() {
  return (
    <>
      <ImagesProductsComp />
    </>
  );
}

export default ImagesProducts;
