export const DataLinks = [
  {
    id: 1,
    title: "Galery",
    path: "/admin/galery",
    icon: "albums-outline",
  },
  {
    id: 2,
    title: "Category",
    path: "/admin/category",
    icon: "copy-outline",
  },
  {
    id: 3,
    title: "Products",
    path: "/admin/products",
    icon: "cart-outline",
  },
  {
    id: 3,
    title: "Products Images",
    path: "/admin/productsimages",
    icon: "images-outline",
  },
  {
    id: 4,
    title: "Requests",
    path: "/admin/request",
    icon: "people-circle-outline",
  },
  {
    id: 5,
    title: "Admin",
    path: "/admin/admin",
    icon: "person-circle-outline",
  },
];
