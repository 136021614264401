import React, { useEffect, useState } from "react";
import HeaderComp from "../common/headerCommon";
import { useDispatch, useSelector } from "react-redux";
import { GetCategory } from "../../redux/store/category";
import { Col, Row } from "react-grid-system";
import styled from "./style.module.css";
import GaleryPost from "../Galery/Post";
import CategoryPost from "./Post";
import CategoryDelete from "./Delete";
import CategoryPut from "./Put";

function CategoriesComp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetCategory());
  }, []);
  const Data = useSelector((state) => state.category.getCategory.Data);
  const [modal, setModal] = useState(false);
  const [delet, setDelete] = useState(false);
  const [put, setPut] = useState(false);
  const [del_id, setDel_id] = useState();
  const [put_id, setPut_id] = useState();

  const HandelOpen = () => {
    setModal(true);
  };
  const HandelClose = () => {
    setModal(false);
  };
  const HandelOpenDel = () => {
    setDelete(true);
  };
  const HandelCloseDel = () => {
    setDelete(false);
  };
  const HandelOpenPut = () => {
    setPut(true);
  };
  const HandelClosePut = () => {
    setPut(false);
  };
  return (
    <>
      <HeaderComp title="Category" handelOpen={HandelOpen} />
      <Row style={{ margin: 0, padding: 0, width: "97%" }}>
        {Data.map((elem, index) => (
          <Col key={index} xs={4} sm={4} className={styled.Col}>
            <h2 className={styled.Text}>{elem.category_name}</h2>
            <h2 className={styled.Text}>{elem.category_name_uz}</h2>
            <h2 className={styled.Text}>{elem.category_name_en}</h2>
            <div className={styled.buttons}>
              <button
                onClick={() => {
                  setDel_id(elem.id);
                  HandelOpenDel();
                }}
                className={styled.delete}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setPut_id(elem.id);
                  HandelOpenPut();
                }}
                className={styled.put}
              >
                Edit
              </button>
            </div>
          </Col>
        ))}
      </Row>
      <CategoryPost modal={modal} handleClose={HandelClose} />
      <CategoryDelete modal={delet} handleClose={HandelCloseDel} id={del_id} />
      <CategoryPut modal={put} handleClose={HandelClosePut} id={put_id} />
    </>
  );
}

export default CategoriesComp;
