import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services";

export const PostProducts = createAsyncThunk("Products/post", async (body) => {
  return axios.post(`${Api_Url}/products`, body).then((res) => res);
});
export const GetProducts = createAsyncThunk("Products/get", async () => {
  return await axios
    .get(`${Api_Url}/products`)
    .then((response) => response.data);
});
export const DeleteProducts = createAsyncThunk(
  "Products/delete",
  async (id) => {
    return await axios
      .delete(`${Api_Url}/products/${id}`)
      .then((response) => response.data);
  }
);
export const PutProducts = createAsyncThunk(
  "Products/put",
  async ({ body, id }) => {
    return await axios
      .put(`${Api_Url}/products/${id}`, body)
      .then((response) => console.log(response));
  }
);
const ProductsSlice = createSlice({
  name: "Products",
  initialState: {
    postProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getProducts: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    [PostProducts.pending]: (state, action) => {
      state.postProducts.Loading = true;
    },
    [PostProducts.fulfilled]: (state, action) => {
      state.postProducts.Error = false;
      state.postProducts.Success = true;
      state.postProducts.Loading = false;
    },
    [PostProducts.rejected]: (state, action) => {
      state.postProducts.Error = true;
      state.postProducts.Success = false;
      state.postProducts.Loading = false;
    },
    [GetProducts.pending]: (state, action) => {
      state.getProducts.Loading = true;
    },
    [GetProducts.fulfilled]: (state, action) => {
      state.getProducts.Error = false;
      state.getProducts.Success = true;
      state.getProducts.Loading = false;
      state.getProducts.Data = action.payload;
    },
    [GetProducts.rejected]: (state, action) => {
      state.getProducts.Error = true;
      state.getProducts.Success = false;
      state.getProducts.Loading = false;
      state.getProducts.Data = [];
    },
    [DeleteProducts.pending]: (state, action) => {
      state.deleteProducts.Loading = true;
    },
    [DeleteProducts.fulfilled]: (state, action) => {
      state.deleteProducts.Error = false;
      state.deleteProducts.Success = true;
      state.deleteProducts.Loading = false;
    },
    [DeleteProducts.rejected]: (state, action) => {
      state.deleteProducts.Error = true;
      state.deleteProducts.Success = false;
      state.deleteProducts.Loading = false;
    },
    [PutProducts.pending]: (state, action) => {
      state.putProducts.Loading = true;
    },
    [PutProducts.fulfilled]: (state, action) => {
      state.putProducts.Error = false;
      state.putProducts.Success = true;
      state.putProducts.Loading = false;
    },
    [PutProducts.rejected]: (state, action) => {
      state.putProducts.Error = true;
      state.putProducts.Success = false;
      state.putProducts.Loading = false;
    },
  },
});

export default ProductsSlice.reducer;
