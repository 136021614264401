import React, { useEffect, useState } from "react";
import HeaderComp from "../common/headerCommon";
import { GetProducts, PostProducts } from "../../redux/store/products";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import styled from "./style.module.css";
import ProductsPost from "./Post";
import ProductsDelete from "./Delete";
import ProductsPut from "./Put";

function ProductsComp() {
  const disptach = useDispatch();
  useEffect(() => {
    disptach(GetProducts());
  }, []);
  const Data = useSelector((state) => state.products.getProducts.Data);

  const [modal, setModal] = useState(false);
  const [delet, setDelete] = useState(false);
  const [put, setPut] = useState(false);
  const [del_id, setDel_id] = useState();
  const [put_id, setPut_id] = useState();

  const HandelOpen = () => {
    setModal(true);
  };
  const HandelClose = () => {
    setModal(false);
  };
  const HandelOpenDel = () => {
    setDelete(true);
  };
  const HandelCloseDel = () => {
    setDelete(false);
  };
  const HandelOpenPut = () => {
    setPut(true);
  };
  const HandelClosePut = () => {
    setPut(false);
  };
  console.log(Data);
  return (
    <>
      <HeaderComp title="Products" handelOpen={HandelOpen} />
      <Row style={{ margin: 0, padding: 0, width: "97%" }}>
        {Data.map((elem, index) => (
          <Col key={index} xs={4} sm={4} className={styled.Col}>
            <div className={styled.texts}>
              <h2>{elem.title}</h2>
              <h3>{elem.description_uz}</h3>
              <h3>{elem.description_en}</h3>
              <h3>{elem.description_ru}</h3>
              <h3>Category: {elem.category.category_name}</h3>
            </div>
            <div className={styled.buttons}>
              <button
                onClick={() => {
                  setDel_id(elem.id);
                  HandelOpenDel();
                }}
                className={styled.delete}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setPut_id(elem.id);
                  HandelOpenPut();
                }}
                className={styled.put}
              >
                Edit
              </button>
            </div>
          </Col>
        ))}
      </Row>
      <ProductsPost modal={modal} handleClose={HandelClose} />
      <ProductsDelete modal={delet} handleClose={HandelCloseDel} id={del_id} />
      <ProductsPut modal={put} handleClose={HandelClosePut} id={put_id} />
    </>
  );
}

export default ProductsComp;
