import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { GetCategory, PostCategory } from "../../../redux/store/category";

function CategoryPost({ modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const title = useRef();
  const title_uz = useRef();
  const title_en = useRef();
  const HandePost = async (e) => {
    e.preventDefault();
    const body = {
      category_name: title.current.value,
      category_name_en: title_en.current.value,
      category_name_uz: title_uz.current.value,
    };
    await dispatch(PostCategory(body));
    dispatch(GetCategory());
    handleClose();
  };

  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <form className={styled.form} onSubmit={HandePost}>
                <input ref={title} type="text" placeholder="title Ru" />
                <input ref={title_uz} type="text" placeholder="title Uz" />
                <input ref={title_en} type="text" placeholder="title En" />
                <button type="submit" className={styled.postBtn}>
                  Add Category
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default CategoryPost;
