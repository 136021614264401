import { Route, Routes } from "react-router-dom";
import { DataRouters } from "./data-router";
import Saidbar from "../components/Saidbar";
import { Main, WrapperContainer } from "../App-styled";
import Cookies from "universal-cookie";

function AllRouter() {
  const cookies = new Cookies();
  const Token = cookies.get("autToken");
  return (
    <>
      <Main>
        {Token ? (
          <>
            <Saidbar />
          </>
        ) : null}
        <WrapperContainer>
          <Routes>
            {DataRouters.map((elem, index) => (
              <Route key={index} path={elem.path} element={elem.Element} />
            ))}
          </Routes>
        </WrapperContainer>
      </Main>
    </>
  );
}

export default AllRouter;
