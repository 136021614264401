import React from "react";
import FormComp from "../../components/Form";

function Form() {
  return (
    <>
      <FormComp />
    </>
  );
}

export default Form;
