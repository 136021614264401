import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const GetAdmin = createAsyncThunk("Admin/get", async () => {
  return await axios
    .get("https://x8ki-letl-twmt.n7.xano.io/api:BAHjxkQw/user")
    .then((response) => response.data);
});
export const DeleteAdmins = createAsyncThunk("Admin/delete", async (id) => {
  return await axios
    .delete(`https://x8ki-letl-twmt.n7.xano.io/api:BAHjxkQw/user/${id}`)
    .then((response) => response.data);
});
export const PostAdmin = createAsyncThunk("Admin/post", async (body) => {
  return axios
    .post("https://x8ki-letl-twmt.n7.xano.io/api:BAHjxkQw/user", body)
    .then((res) => res);
});

const AdminSlice = createSlice({
  name: "Admin",
  initialState: {
    getAdmin: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteAdmin: {
      Error: false,
      Loading: false,
      Success: false,
    },
    postAdmin: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    [PostAdmin.pending]: (state, action) => {
      state.postAdmin.Loading = true;
    },
    [PostAdmin.fulfilled]: (state, action) => {
      state.postAdmin.Error = false;
      state.postAdmin.Success = true;
      state.postAdmin.Loading = false;
    },
    [PostAdmin.rejected]: (state, action) => {
      state.postAdmin.Error = true;
      state.postAdmin.Success = false;
      state.postAdmin.Loading = false;
    },
    [GetAdmin.pending]: (state, action) => {
      state.getAdmin.Loading = true;
    },
    [GetAdmin.fulfilled]: (state, action) => {
      state.getAdmin.Error = false;
      state.getAdmin.Success = true;
      state.getAdmin.Loading = false;
      state.getAdmin.Data = action.payload;
    },
    [GetAdmin.rejected]: (state, action) => {
      state.getAdmin.Error = true;
      state.getAdmin.Success = false;
      state.getAdmin.Loading = false;
      state.getAdmin.Data = [];
    },
    [DeleteAdmins.pending]: (state, action) => {
      state.deleteAdmin.Loading = true;
    },
    [DeleteAdmins.fulfilled]: (state, action) => {
      state.deleteAdmin.Error = false;
      state.deleteAdmin.Success = true;
      state.deleteAdmin.Loading = false;
    },
    [DeleteAdmins.rejected]: (state, action) => {
      state.deleteAdmin.Error = true;
      state.deleteAdmin.Success = false;
      state.deleteAdmin.Loading = false;
    },
  },
});

export default AdminSlice.reducer;
