import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCategory } from "../../../redux/store/category";
import { GetProducts, PostProducts } from "../../../redux/store/products";

function ProductsPost({ modal, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const title = useRef();
  const description_uz = useRef();
  const description_en = useRef();
  const description_ru = useRef();
  const [categId, setCategId] = useState();
  const HandelChange = (e) => {
    setCategId(e.target.value);
  };
  const HandePost = async (e) => {
    e.preventDefault();
    const body = {
      title: title.current.value,
      description_uz: description_uz.current.value,
      description_en: description_en.current.value,
      description_ru: description_ru.current.value,
      category: categId,
    };
    await dispatch(PostProducts(body));
    dispatch(GetProducts());
    handleClose();
  };
  useEffect(() => {
    dispatch(GetCategory());
  }, []);
  const CategoryData = useSelector((state) => state.category.getCategory.Data);
  return (
    <>
      <div>
        <Modal
          open={modal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styled.Wrapper}>
              <form className={styled.form} onSubmit={HandePost}>
                <input ref={title} type="text" placeholder="title" />
                <input
                  ref={description_uz}
                  type="text"
                  placeholder="description_uz"
                  required
                />
                <input
                  ref={description_en}
                  type="text"
                  placeholder="description_en"
                  required
                />
                <input
                  ref={description_ru}
                  type="text"
                  placeholder="description_ru"
                  required
                />
                <select onChange={HandelChange} required>
                  <option>Choose Category</option>
                  {CategoryData.map((elem, index) => (
                    <>
                      <option value={elem.id}>{elem.category_name}</option>
                    </>
                  ))}
                </select>
                <h3>
                {categId ? <>
                  {CategoryData.map((elem, index) =>
                    elem.id == categId ? (
                      <>{elem.category_name}</>
                    ) : (
                      <></>
                    )
                  )}
                </>: <>You don't choose category yet !</>}
                </h3>
                <button type="submit" className={styled.postBtn}>
                  Add Product
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ProductsPost;
